.flex {
	display: flex;
}

.flex_spaceBetween {
	display: flex;
	justify-content: space-between;
}

.center_extAlign {
	text-align: center;
}
